.social-icons a {
    color:white;
    font-size: 24px;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #007bff; /* Changes color on hover */
  }
  .lakshyalogo{
    height:50px;
    width:130px;
  }
  .rightside {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns */
    grid-template-rows: auto auto;  /* 2 rows */
    gap: 10px;
  }
  /* Styling h3 tags */
.footer-bottom h3 {
  color: white; /* White color */
  line-height: 1.5; /* Adjust line height */
  margin-bottom: 15px; /* Optional: Add space below the heading */
  font-size: 18px; /* Adjust font size if needed */
}

/* Styling anchor links */
.footer-bottom a {
  color: white; /* White text for links */
  font-size: 14px; /* Reduce font size for links */
  text-decoration: none; /* Remove underline */
  line-height: 2.4; /* Adjust line height for spacing */
}

/* Hover effect for links */
.footer-bottom a:hover {
  color: #ccc; /* Light gray on hover */
  text-decoration: underline; /* Optional underline on hover */
}
.footer-bottom h4{
  color:white;
  font-size: 18px;
}


  
