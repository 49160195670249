 .content1{
    padding: 20px;
    margin-top:70px;;
} 
client-area {
    padding: 20px 0;
    overflow: hidden; /* Ensures the scrolling is contained */
    background-color: #f9f9f9;
  }
  
  .clients {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px; /* Space between logos */
    animation: scroll-horizontal 20s linear infinite; /* Continuous scrolling */
  }
  
  .client-logo {
    flex-shrink: 0; /* Prevents the logo from shrinking */
    width: 150px; /* Set a fixed width for logos */
    height: auto;
  }
  
  .client-logo img {
    width: 100%; /* Makes sure the image takes up the full logo container */
    display: block;
  }
  
  @keyframes scroll-horizontal {
    0% {
      transform: translateX(0); /* Start at original position */
    }
    100% {
      transform: translateX(-100%); /* Move left by 100% of the container width */
    }
  }
  .section-title1 {
    font-size: 32px;
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: bold;
    position: relative;
  }
 
  .about-service3-area h1 {
    color: #000000;
    font-family: var(--font_yantramanv);
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -2px;
    margin-bottom: 40px;
}

.about-service3-area p {
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
}