.header{
    position:fixed;
    top: 0;
    left:0;
    width: 100%;
    background-color: #333;
    z-index: 1000;
}
.non-clickable{
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: inherit;
}
.list-container  {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 10px; /* Space between the list items */
    list-style: none;
    padding:0px;
    
  }

  
 
  
  .mega-menu-link {
    padding: 10px;
  }
  
  .servicesheading {
    font-size: 20px; /* Adjust as needed */
    margin-bottom: 10px; /* Adds space between heading and list */
  }
  
  .solutionsheading{
    margin-left:35px;
  }
  .list-container li{
    line-height: 1px;
  }
  
